import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DailyTasks from './components/DailyTasks';
import Wordle from './components/Wordle';

const router = createBrowserRouter([
  {path: '/', element: <App/>},
  {path: '/dailyTasks', element: <DailyTasks/>},
  {path: '/wordle', element: <Wordle/>},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

